import { render, staticRenderFns } from "./miniprogram_hotSearch.vue?vue&type=template&id=3d3f6bbc&scoped=true&"
import script from "./miniprogram_hotSearch.vue?vue&type=script&lang=js&"
export * from "./miniprogram_hotSearch.vue?vue&type=script&lang=js&"
import style0 from "./miniprogram_hotSearch.vue?vue&type=style&index=0&id=3d3f6bbc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d3f6bbc",
  null
  
)

export default component.exports